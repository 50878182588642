import React, { useCallback } from 'react'
import { Header } from './Header'
import { Scorebar } from './scorebar/Scorebar'
import { HowToPlayModal } from './modals/HowToPlayModal'
import { StatsModal } from './modals/StatsModal'
import { Stage } from './Stage'
import { Keynoteboard } from './Keynoteboard'
import { Keynote } from './Keynote'
import { Alert } from './Alert'
import {
  GAME_TITLE,
  STATISTICS_TITLE,
  LAST_NOTE_PLAYBACK_TEXT,
} from '../constants/strings'
import { getGameLogic } from '../lib/gameLogic'
import { getTodayStats, getAllTimeStats, getDayIndex } from '../lib/gameData'
import { saveSession } from '../lib/sessionStorage'
import { ALERT_TIME_MS } from '../constants/numbers'
import { Statline } from '../lib/Statline'
import { KeyboardView } from './KeyboardView'
import { KEYBOARD_MAP } from '../lib/keyboard'
import { window } from '../lib/window'
import { IoIosRefresh } from 'react-icons/io'

export const JingleShower = () => {
  const [alert, setAlert] = React.useState('')
  const [isHowToPlayModalOpen, setIsHowToPlayModalOpen] = React.useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = React.useState(false)
  const [toggleRerender, setToggleRerender] = React.useState<boolean>(false)

  const [isPlaying, setIsPlaying] = React.useState<boolean>(false)
  const [isGameComplete, setIsGameComplete] = React.useState<boolean>(false)
  const [showKeyboard, setShowKeyboard] = React.useState(false)

  React.useLayoutEffect(() => {
    if (isGameComplete) {
      getGameLogic().updateGameDataWithFinishedGame({
        found: getGameLogic().getFoundCount(),
        percentage: getGameLogic().getPercentage(),
        bestStreak: getGameLogic().getBestStreak(),
      } as Statline)

      saveSession(
        getGameLogic().getAttemptNumber(),
        getDayIndex(),
        getGameLogic().getFoundCount(),
        getGameLogic().getGuessCount(),
        getGameLogic().getBestStreak(),
        getGameLogic().getSolution(),
        getGameLogic().getMissedNotes()
      )

      setIsPlaying(false)
      setIsStatsModalOpen((isOpen) => {
        return true
      })
    }
  }, [isGameComplete])

  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const { key } = e
      if (key === 'Enter') {
        if (!isPlaying) {
          onStartButtonClick()
        }
      } else if (key === '.') {
        if (isPlaying) {
          stopClickHandler()
        }
      } else {
        if (isPlaying) {
          handleKeyboardKeypress(e)
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying])

  React.useEffect(() => {
    if (!getGameLogic().hasExistingGameData()) {
      setIsHowToPlayModalOpen(true)
    }
  }, [])

  const invokeGameCompletion = useCallback(() => {
    setIsGameComplete(true)
  }, [])

  const invokeScorebarUpdate = useCallback(() => {
    setToggleRerender((val: boolean) => !val)
  }, [])

  const onStartButtonClick = () => {
    getGameLogic().start()
    setIsGameComplete(false)
    setIsPlaying(true)
  }

  const stopClickHandler = () => {
    setIsPlaying(false)
  }

  const onKeynote = (value: string) => {
    if (!isPlaying || !getGameLogic().isCountdownFinished()) {
      return
    }

    let status = getGameLogic().handleKeynoteAndReturnStatus(value)
    if (status !== 'EMPTY') {
      invokeScorebarUpdate()
    }
  }

  const handleKeyboardKeypress = (e: KeyboardEvent) => {
    if (KEYBOARD_MAP[e.key]) {
      onKeynote(KEYBOARD_MAP[e.key])
    }
  }

  const onLastNotePlayback = () => {
    getGameLogic().playLastNote()
  }

  const toggleHowToPlayModalOpen = () => {
    setIsHowToPlayModalOpen((isOpen) => {
      return !isOpen
    })
  }

  const toggleStatsModalOpen = () => {
    setIsStatsModalOpen((isOpen) => {
      return !isOpen
    })
  }

  const toggleShowKeyboard = () => {
    setShowKeyboard((showKeyboard) => {
      return !showKeyboard
    })
  }

  return (
    <div className="h-full flex flex-col justify-between">
      <div className="select-none flex flex-col h-full w-full max-w-[380px] mx-auto">
        <Header
          title={GAME_TITLE}
          toggleHowToPlayModalOpen={toggleHowToPlayModalOpen}
          toggleStatsModalOpen={toggleStatsModalOpen}
        />
        <HowToPlayModal
          isOpen={isHowToPlayModalOpen}
          handleClose={() => setIsHowToPlayModalOpen(false)}
          handleSoundCheck={getGameLogic().playStartNote}
        />
        <StatsModal
          isPlaying={isPlaying}
          today={getTodayStats(getDayIndex())}
          allTime={getAllTimeStats()}
          replay={onStartButtonClick}
          isOpen={isStatsModalOpen}
          handleClose={() => setIsStatsModalOpen(false)}
          handleNotification={(message: string) => {
            return () => {
              setAlert(message)
              setTimeout(() => setAlert(''), ALERT_TIME_MS)
            }
          }}
          title={STATISTICS_TITLE}
        />
        <div className="flex grow max-h-[50rem] justify-center border border-solid rounded-lg bg-white">
          <div className="flex flex-col h-full">
            <Stage
              isPlaying={isPlaying}
              isGameComplete={isGameComplete}
              onStart={onStartButtonClick}
              invokeScorebarUpdate={invokeScorebarUpdate}
              invokeGameCompletion={invokeGameCompletion}
            />
            <Scorebar />
          </div>
          <div className="w-[80px]">
            <Keynoteboard
              onKeynote={onKeynote}
              onLastNotePlayback={onLastNotePlayback}
            >
              <Keynote
                height={80}
                onClick={onLastNotePlayback}
                value="LAST_NOTE_PLAYBACK"
              >
                <div className="w-full flex justify-center">
                  <span>
                    <IoIosRefresh
                      size={window.innerWidth > 300 ? '2em' : '1.5em'}
                    ></IoIosRefresh>
                  </span>
                  <span className="text-xs py-1">
                    {LAST_NOTE_PLAYBACK_TEXT}
                  </span>
                </div>
              </Keynote>
            </Keynoteboard>
            <Alert message={alert} isOpen={alert !== ''} variant="success" />
          </div>
        </div>
      </div>
      <div className="text-gray-600 hidden md:block ext-sm select-none w-full text-center">
        <button
          className="text-sm outline-0 p-1 border border-solid"
          onClick={toggleShowKeyboard}
        >
          Toggle Keyboard Mapping
        </button>
        {showKeyboard ? <KeyboardView /> : ''}
      </div>
    </div>
  )
}
