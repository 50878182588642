import { NOTES_PER_GAME, STREAK_THRESHOLD } from '../lib/gameParams'

export const GAME_TITLE = 'Jingle Shower'
export const LAST_NOTE_PLAYBACK_TEXT = 'Note'
export const STATISTICS_TITLE = 'Statistics'
export const NEXT_SHOWER_TEXT = 'Next shower in:'
export const SHARE_TEXT = 'Share'
export const JINGLE_SHOWER_URL = 'https://shower.dailyjingle.me'
export const GAME_COPIED_MESSAGE = 'Game copied to clipboard'
export const ALLTIME_COPIED_MESSAGE = 'All-Time Stats copied to clipboard'
export const GAME_STATS_RESET_MESSAGE = 'Game Stats Reset!'

export type STATUS = 'CORRECT' | 'INCORRECT' | 'MISS' | 'EMPTY'
export type COLOR = 'RED' | 'GREEN' | 'YELLOW' | 'NONE'

export const CORRECT_MESSAGES = ['Great Job!', 'Awesome', 'Well done!']

export const NOT_YET_PLAYED = 'You have not played today!'

export const PLAY_TEXT = 'Play'
export const REPLAY_TEXT = 'Replay'

export const ACHIEVEMENT_LABEL = 'Achievements:'

export const ATTEMPT_LABEL = 'Attempt'
export const ATTEMPT_DESCRIPTION = '# games today'

export const FOUND_LABEL = 'Found'
export const FOUND_DESCRIPTION = `out of ${NOTES_PER_GAME}`

export const CORRECT_LABEL = '% Correct'
export const CORRECT_DESCRIPTION = 'found / guesses'

export const BEST_STREAK_LABEL = 'Best Streak'

export const RAIN_BADGE_LABEL = 'Rain Badge:'
export const RAIN_BADGE_DESCRIPTION = `${STREAK_THRESHOLD}+ streak`

export const CLOUD_BADGE_LABEL = 'Cloud Badge:'
export const CLOUD_BADGE_DESCRIPTION = 'All notes found'

export const LIGHTNING_BADGE_LABEL = 'Lightning Badge:'
export const LIGHTNING_BADGE_DESCRIPTION = '100% correct'

export const ordinal = (n: number): string => {
  let s = ['th', 'st', 'nd', 'rd']
  let v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

export const RESET_STATS_LABEL = 'Reset Stats'
export const FIRST_ATTEMPTS_LABEL = 'First Attempts:'
export const AVERAGE_PERCENTAGE_LABEL = 'Average percentage:'
export const AVERAGE_BEST_STREAK_LABEL = 'Average best streak:'
export const ALLTIME_BEST_STREAK_LABEL = 'All-time best streak:'
