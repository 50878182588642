import { Statline } from './Statline'
import {
  loadGameDataFromLocalStorage,
  saveGameDataToLocalStorage,
  clearStorageAndCache,
} from './localStorage'
import { EPOCH_MS, MS_IN_DAY } from '../constants/numbers'
import { NOTES_PER_GAME, STREAK_THRESHOLD } from './gameParams'

export type ACHIEVEMENT_KEY = 'found100' | 'streakHit' | 'correct100'

export type TodayStats = {
  firstAttempt: Statline | null
  lastAttempt: Statline | null
  totalAttempts: number
  dayIndex: number
  achievements: { [key in ACHIEVEMENT_KEY]: Achievement }
}

export type AllTimeStats = {
  averageFirstAttempt: Statline | null
  firstAttemptBestStreak: number
  firstAttemptTracker: [Date, Statline][]
  consecutiveDaysPlayed: number
  mostRecentDayIndex: number
}

export type Achievement = {
  achieved: boolean
  attemptNumber: number
}

export type StoredGameData = {
  today: TodayStats
  allTime: AllTimeStats
}

export type InternalStoredGameData = {
  gameData: StoredGameData
  anonId: string
}

let _todayData: TodayStats | null
let _allTimeData: AllTimeStats | null

export const clearCache = () => {
  _todayData = null
  _allTimeData = null
}

export const getDayIndex = (now?: number): number => {
  const index = Math.floor((now ? now : Date.now() - EPOCH_MS) / MS_IN_DAY)
  return index
}

export const getTodayStats = (day: number): TodayStats => {
  if (_todayData) {
    return _todayData
  }

  let data

  try {
    data = loadGameDataFromLocalStorage()
  } catch (e) {
    console.log('clearing localStorage, malformed data found')
    clearStorageAndCache()
  }

  if (!data || !data.today || data.today.dayIndex !== day) {
    _todayData = {
      firstAttempt: null,
      lastAttempt: null,
      totalAttempts: 0,
      dayIndex: 0,
      achievements: {
        found100: {
          achieved: false,
          attemptNumber: 0,
        },
        streakHit: {
          achieved: false,
          attemptNumber: 0,
        },
        correct100: {
          achieved: false,
          attemptNumber: 0,
        },
      },
    }
  } else {
    _todayData = data.today
  }
  return _todayData
}

export const getAllTimeStats = (): AllTimeStats => {
  if (_allTimeData) {
    return _allTimeData
  }

  let data
  try {
    data = loadGameDataFromLocalStorage()
  } catch (e) {
    console.log('clearing localStorage, malformed data found')
    clearStorageAndCache()
  }

  if (!data || !data.allTime) {
    _allTimeData = {
      averageFirstAttempt: null,
      firstAttemptBestStreak: 0,
      firstAttemptTracker: [],
      consecutiveDaysPlayed: 1,
      mostRecentDayIndex: 0,
    }
  } else {
    _allTimeData = data.allTime
  }

  return _allTimeData
}

export const getGameData = (dayIndex: number): StoredGameData => {
  return {
    today: getTodayStats(dayIndex),
    allTime: getAllTimeStats(),
  } as StoredGameData
}

const updateConsecutiveDaysPlayed = (
  allTime: AllTimeStats,
  todayIndex: number
): void => {
  let index = todayIndex
  let diff = index - allTime.mostRecentDayIndex

  if (diff === 0) {
    // same day, do nothing
    return
  } else if (diff === 1) {
    // consecutive day, increment consecutive days played
    allTime.consecutiveDaysPlayed += 1
  } else if (diff > 1) {
    // not a consecutive day, reset consecutive days played
    allTime.consecutiveDaysPlayed = 1
  }
  allTime.mostRecentDayIndex = index
  _allTimeData = { ...allTime }
}

const updateAllTimeStats = (
  line: Statline,
  allTime: AllTimeStats,
  attemptNumber: number,
  todayIndex: number
): AllTimeStats => {
  updateConsecutiveDaysPlayed(allTime, todayIndex)

  if (attemptNumber === 1) {
    let temp: number
    let existingFirstAttemptCount: number = allTime.firstAttemptTracker.length

    if (existingFirstAttemptCount === 0 || !allTime.averageFirstAttempt) {
      allTime.averageFirstAttempt = { ...line }
    } else {
      temp = allTime.averageFirstAttempt.found * existingFirstAttemptCount
      allTime.averageFirstAttempt.found =
        (temp + line.found) / (existingFirstAttemptCount + 1)

      temp = allTime.averageFirstAttempt.percentage * existingFirstAttemptCount
      allTime.averageFirstAttempt.percentage =
        (temp + line.percentage) / (existingFirstAttemptCount + 1)

      temp = allTime.averageFirstAttempt.bestStreak * existingFirstAttemptCount
      allTime.averageFirstAttempt.bestStreak =
        (temp + line.bestStreak) / (existingFirstAttemptCount + 1)
    }

    if (line.bestStreak > allTime.firstAttemptBestStreak) {
      allTime.firstAttemptBestStreak = line.bestStreak
    }
    allTime.firstAttemptTracker.push([new Date(), line])
  }
  _allTimeData = { ...allTime }
  return _allTimeData
}

const updateTodayStats = (
  line: Statline,
  today: TodayStats,
  attemptNumber: number,
  todayIndex: number
): TodayStats => {
  if (attemptNumber === 1) {
    today.firstAttempt = { ...line }
  }
  today.achievements = updateAchievements(line, today, attemptNumber)
  today.lastAttempt = { ...line }
  today.totalAttempts = attemptNumber
  today.dayIndex = todayIndex

  _todayData = { ...today }
  return _todayData
}

const hasAchievedAward = (
  achievementFunc: Function,
  line: Statline
): boolean => {
  return achievementFunc(line)
}

const found100 = (line: Statline): boolean => {
  return line.found === NOTES_PER_GAME
}

const streakHit = (line: Statline): boolean => {
  return line.bestStreak >= STREAK_THRESHOLD
}

const correct100 = (line: Statline): boolean => {
  return line.percentage === 100
}

const achievementCriteria: { [key in ACHIEVEMENT_KEY]: Function } = {
  found100: found100,
  streakHit: streakHit,
  correct100: correct100,
}

const updateAchievements = (
  lastAttempt: Statline,
  today: TodayStats,
  attemptNumber: number
): { [key in ACHIEVEMENT_KEY]: Achievement } => {
  Object.keys(today.achievements).forEach((key) => {
    if (today.achievements[key as ACHIEVEMENT_KEY].achieved) {
      return today.achievements
    }

    if (
      hasAchievedAward(achievementCriteria[key as ACHIEVEMENT_KEY], lastAttempt)
    ) {
      today.achievements[key as ACHIEVEMENT_KEY].achieved = true
      today.achievements[key as ACHIEVEMENT_KEY].attemptNumber = attemptNumber
    }
  })
  return today.achievements
}

export const logStatline = (
  line: Statline,
  gameData: StoredGameData,
  attemptNumber: number,
  todayIndex: number
): StoredGameData => {
  let today = gameData.today
  let allTime = gameData.allTime

  if (!allTime) {
    _allTimeData = {
      averageFirstAttempt: { ...line },
      firstAttemptBestStreak: line.bestStreak,
      firstAttemptTracker: attemptNumber === 1 ? [[new Date(), line]] : [],
      consecutiveDaysPlayed: 1,
      mostRecentDayIndex: todayIndex,
    }
  } else {
    allTime = updateAllTimeStats(line, allTime, attemptNumber, todayIndex)
    _allTimeData = { ...allTime }
  }

  today = updateTodayStats(line, today, attemptNumber, todayIndex)
  _todayData = { ...today }

  saveGameDataToLocalStorage({
    today: _todayData,
    allTime: _allTimeData,
  } as StoredGameData)

  return loadGameDataFromLocalStorage() as StoredGameData
}
