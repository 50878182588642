import { FPS } from './frameLogic'

const SMALL_HEIGHT = 240
const DEFAULT_HEIGHT = 400
const LARGE_HEIGHT = 550

const SMALL_WIDTH = 230
const DEFAULT_WIDTH = 300

let CANVAS_HEIGHT = SMALL_HEIGHT
let CANVAS_WIDTH = DEFAULT_WIDTH

if (window.innerWidth < 320) {
  CANVAS_WIDTH = SMALL_WIDTH
}

if (window.innerHeight > 615 && window.innerHeight < 815) {
  CANVAS_HEIGHT = DEFAULT_HEIGHT
} else if (window.innerHeight > 815) {
  CANVAS_HEIGHT = LARGE_HEIGHT
}

export const PIXEL_RATIO_ADJUSTMENT = 2

CANVAS_HEIGHT = CANVAS_HEIGHT * PIXEL_RATIO_ADJUSTMENT
CANVAS_WIDTH = CANVAS_WIDTH * PIXEL_RATIO_ADJUSTMENT

export { CANVAS_HEIGHT }
export { CANVAS_WIDTH }

export const SCALE = CANVAS_HEIGHT / DEFAULT_HEIGHT

export const SPRITE_WIDTH = 3 * SCALE
export const SPRITE_HEIGHT = 9 * SCALE
export const NOTE_SPEED = 40 / FPS

export const TTL_FINISHED_SPRITE = 60 * (FPS / 60)
export const NOTES_PER_GAME = 15
export type RGB = [Number, Number, Number]
export const INITIAL_SECONDS_BEFORE_NEXT_NOTE = 5
export const BUFFER_BETWEEN_CORRECT_NOTES = 1.5
export const PAUSE_THRESHOLD = 3
export const STREAK_THRESHOLD = 6
export const RADIUS_THRESHOLD = 50

export type STATUS = 'FOUND' | 'LOST' | 'MISS' | 'EMPTY'
export type COLOR = 'RED' | 'GREEN' | 'YELLOW' | 'NONE'

export const SPRITE = String.fromCharCode(parseInt('0x2669', 16))
export const COLORMAP: { [key: string]: [number, number, number] } = {
  C: [254, 192, 198],
  'C#': [254, 210, 205],
  D: [238, 221, 204],
  'D#': [247, 217, 163],
  E: [255, 241, 179],
  F: [232, 242, 227],
  'F#': [218, 237, 236],
  G: [231, 238, 246],
  'G#': [220, 229, 238],
  A: [200, 225, 238],
  'A#': [233, 223, 236],
  B: [245, 214, 235],
}

export const CSSCOLORMAP: { [key: string]: string } = {
  C: 'bg-[#fec0c6]',
  'C#': 'bg-[#fed2cd]',
  D: 'bg-[#eeddcc]',
  'D#': 'bg-[#f7daa3]',
  E: 'bg-[#fff1b3]',
  F: 'bg-[#e8f2e3]',
  'F#': 'bg-[#daedec]',
  G: 'bg-[#e7eef6]',
  'G#': 'bg-[#dce5ee]',
  A: 'bg-[#c8e1ee]',
  'A#': 'bg-[#e9dfec]',
  B: 'bg-[#f5d6eb]',
}
