import { ReactNode } from 'react'
import { KeyValue } from '../lib/keyboard'
import { CSSCOLORMAP } from '../lib/gameParams'

type Props = {
  children?: ReactNode
  value: KeyValue
  width?: number
  height?: number
  onClick: (value: KeyValue) => void
}

export const Keynote = ({
  children,
  width = 53,
  height = 45,
  value,
  onClick,
}: Props) => {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    onClick(value)
    event.currentTarget.blur()
  }

  return (
    <button
      className={`grow border border-solid text-xs font-bold cursor-pointer select-none active:shadow-inner dark:text-white ${CSSCOLORMAP[value]}`}
      onClick={handleClick}
    >
      {children || value}
    </button>
  )
}
