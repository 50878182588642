import { solutionIndex } from './melodies'
import { GAME_TITLE, JINGLE_SHOWER_URL } from '../constants/strings'
import { Statline } from './Statline'
import { Achievement, ACHIEVEMENT_KEY } from './gameData'
import { NOTES_PER_GAME } from './gameParams'

export const shareTodayStatus = (
  line: Statline,
  attemptNumber: number,
  achievements: { [key in ACHIEVEMENT_KEY]: Achievement }
) => {
  let count = 0

  Object.keys(achievements).forEach((key) => {
    if (achievements[key as ACHIEVEMENT_KEY].achieved) {
      count++
    }
  })
  if (attemptNumber === 0) {
    navigator.clipboard.writeText(
      `${GAME_TITLE} ${solutionIndex} \n${JINGLE_SHOWER_URL}`
    )
    return
  }

  navigator.clipboard.writeText(
    `${GAME_TITLE} ${solutionIndex}` +
      '\n' +
      `Attempt #${attemptNumber}` +
      '\n' +
      `Found: ${line.found} / ${NOTES_PER_GAME}` +
      '\n' +
      `Best Streak: ${line.bestStreak}` +
      '\n' +
      `% Correct: ${line.percentage.toFixed(1)}` +
      '\n' +
      `${count === 0 ? '' : count + ' / 3 badges earned today\n'}` +
      '\n' +
      JINGLE_SHOWER_URL
  )
}

export const shareAllTimeStatus = (
  averagePercent: string,
  averageStreak: string,
  allTimeStreak: number
) => {
  navigator.clipboard.writeText(
    `${GAME_TITLE} All-Time Stats` +
      '\n' +
      `1st Go Average Percentage: ${averagePercent}` +
      '\n' +
      `1st Go Average Streak: ${averageStreak}` +
      '\n' +
      `All-Time Best Streak: ${allTimeStreak}` +
      '\n' +
      JINGLE_SHOWER_URL
  )
}
