import { Fragment } from 'react'
import { Transition } from '@headlessui/react'

type Props = {
  isOpen: boolean
  message: string
  variant?: 'success' | 'warning'
}

export const Alert = ({ isOpen, message, variant = 'warning' }: Props) => {
  return (
    <div
      className={`fixed top-0 left-1/2 transform -translate-x-1/2 max-w-sm w-full shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden ${
        variant === 'success' ? 'bg-blue-200 z-20' : ''
      } ${isOpen ? 'block' : 'hidden'}`}
    >
      <div className="p-4">
        <p className="text-sm text-center font-medium text-gray-900">
          {message}
        </p>
      </div>
    </div>
  )
}
