import axios from 'axios'
import { getAnonId } from './localStorage'
import { v4 as uuidv4 } from 'uuid'

export const saveSession = async (
  attemptNumber: number,
  dayIndex: number,
  found: number,
  guessCount: number,
  bestStreak: number,
  solution: string[],
  incorrectNotes: string[]
) => {
  const loadedAnonId = getAnonId()
  const anonId = loadedAnonId ? loadedAnonId : uuidv4()

  const sessionData = {
    anonId,
    attemptNumber,
    dayIndex,
    found,
    guessCount,
    bestStreak,
    solution,
    incorrectNotes,
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log(sessionData, process.env.REACT_APP_API_ENDPOINT)
  }

  try {
    const response = await axios.post(
      process.env.REACT_APP_API_ENDPOINT
        ? process.env.REACT_APP_API_ENDPOINT
        : 'http://localhost:4000/sessions',
      sessionData
    )
    return response
  } catch (err) {
    console.log(err)
  }

  return 'session not sent'
}
