import { InformationCircleIcon, ChartBarIcon } from '@heroicons/react/outline'

type Props = {
  title: string
  toggleHowToPlayModalOpen: Function
  toggleStatsModalOpen: Function
}

export const Header = ({
  title,
  toggleHowToPlayModalOpen,
  toggleStatsModalOpen,
}: Props) => {
  return (
    <>
      <header className="flex items-center p-4">
        <h1 className="text-xl grow font-bold dark:text-white">{title}</h1>
        <InformationCircleIcon
          className="h-6 w-8 cursor-pointer dark:stroke-white"
          onClick={() => {
            toggleHowToPlayModalOpen()
          }}
        />
        <ChartBarIcon
          className="h-6 w-8 cursor-pointer dark:stroke-white"
          onClick={() => {
            toggleStatsModalOpen()
          }}
        />
      </header>
    </>
  )
}
