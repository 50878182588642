import { useEffect, useState } from 'react'

type Props = {
  progressPercentage: number
}

export const ProgressBar = ({ progressPercentage }: Props) => {
  return (
    <div className="h-1 w-full bg-gray-100">
      <div
        style={{ width: `${progressPercentage}%` }}
        className={`h-full bg-[#646464]`}
      ></div>
    </div>
  )
}
