import React from 'react'
import './App.css'
import { JingleShower } from './components/JingleShower'

function App() {
  return (
    <>
      <JingleShower />
    </>
  )
}

export default App
