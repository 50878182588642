import Soundfont from 'soundfont-player'
let audioHelper: AudioHelper
let context: AudioContext
let piano: Promise<Soundfont.Player>

const playNote = async (noteValue: string, now: number) => {
  piano.then(function (piano) {
    piano.play(noteValue + '4', now, { duration: 0.5 })
  })
}

const playEmptyNote = async () => {
  piano.then(function (piano) {
    piano.play('C4', 0).stop(context.currentTime)
  })
}

const getCurrentTime = (): number => {
  return context.currentTime
}

export type AudioHelper = {
  playNote: (noteValue: string, now: number) => void
  playEmptyNote: () => void
  getCurrentTime: () => number
}

export const getAudioHelper = () => {
  if (audioHelper) {
    return audioHelper
  }

  return initAudioHelper(window)
}

export const initAudioHelper = (window: any) => {
  context = new AudioContext()
  piano = Soundfont.instrument(context, 'acoustic_grand_piano', { gain: 8 })

  audioHelper = {
    playNote,
    playEmptyNote,
    getCurrentTime,
  }

  return audioHelper
}
