import { getGameLogic } from '../../lib/gameLogic'

let classList: string[]
export const MissesKeeper = () => {
  classList = ['flex flex-col justify-center items-center h-full p-1']

  return (
    <div className={classList.join(' ')}>
      <span className="font-bold">MISSES</span>
      <span>
        {getGameLogic().getGuessCount() - getGameLogic().getFoundCount()}
      </span>
    </div>
  )
}
