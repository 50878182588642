import { BaseModal } from './BaseModal'
import { NOTES_PER_GAME } from '../../lib/gameParams'

type Props = {
  isOpen: boolean
  handleClose: () => void
  handleSoundCheck: () => void
}

export const HowToPlayModal = ({
  isOpen,
  handleClose,
  handleSoundCheck,
}: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <div className="text-xs xs:text-sm">
        <p>Find all {NOTES_PER_GAME} notes in today's shower</p>
        <p>and track your stats. Enjoy!</p>
        <br></br>
        <div className="flex justify-center">
          <img
            className="h-[270px] w-[252px] border border-solid"
            src="/howToPlay.jpg"
          ></img>
        </div>
        <div>
          <div className="flex justify-center">
            <br></br>
          </div>
          <span>Raindrops fall faster the higher your streak.</span>
          <br></br>
          <br></br>

          <button
            type="button"
            className="rounded-md border border-transparent shadow-sm px-1 xs:px-4 py-1 xs:py-2 text-base font-medium focus:outline-none sm:text-sm bg-[#fec0c6] text-black hover:bg-red-200 "
            onClick={handleSoundCheck}
          >
            Sound check: Tap to hear <span className="font-bold">"C"</span>
          </button>
          <br></br>
        </div>

        <br></br>

        <div className="text-slate-400">
          <p className="text-xs">Check if your device is on silent!</p>
          <p className="text-xs">
            Switch it up? Try a different Daily Jingle{' '}
            <a
              className="focus:outline-none font-bold"
              href="https://classic.dailyjingle.me/"
            >
              here!
            </a>
          </p>
        </div>
      </div>
    </BaseModal>
  )
}
