import { Statline } from '../lib/Statline'

type Props = {
  title: string
  line: Statline
  highlight?: boolean
}

export const StatLineView = ({ title, line, highlight = false }: Props) => {
  let displayPercentage: number = 0
  if (!line) {
    line = {
      percentage: 0,
      bestStreak: 0,
      found: 0,
    }
  } else {
    displayPercentage = line.percentage | 0
  }

  return (
    <tr
      className={
        highlight
          ? 'border-4 border-solid border-blue-300 rounded-md border'
          : ''
      }
    >
      <td className="p-3">{title}</td>
      <td className="p-3">{line.found}</td>
      <td className="p-3">{line.bestStreak}</td>
      <td className="p-3">{displayPercentage + '%'}</td>
    </tr>
  )
}
