import {
  CANVAS_WIDTH,
  RADIUS_THRESHOLD,
  SPRITE_HEIGHT,
  SPRITE_WIDTH,
} from './gameParams'

export type MoveableCanvasSprite = {
  spawnTime: number
  speed: number
  positionX: number
  positionY: number
  rgb: [number, number, number]
  draw: (sprite: MoveableCanvasSprite, ctx: CanvasRenderingContext2D) => void
  update: (sprite: MoveableCanvasSprite) => void
}

export interface Note extends MoveableCanvasSprite {
  noteValue: string
  revealed: boolean
  revealTime?: number
  height: number
  width: number
  acceleration: number
}

export interface TextSprite extends MoveableCanvasSprite {
  textValue: string
  centered: boolean
  height: number
}

export interface CorrectSprite extends MoveableCanvasSprite {
  radius: number
}

const drawNote = (sprite: Note, ctx: CanvasRenderingContext2D): void => {
  ctx.fillStyle = `rgb(${sprite.rgb[0]},${sprite.rgb[1]},${sprite.rgb[2]})`

  if (!sprite.revealed) {
    ctx.fillStyle = `rgb(100,100,100)`
  }

  if (!sprite.acceleration) {
    ctx.strokeStyle = `rgb(220,220,220)`
  } else {
    ctx.strokeStyle = `rgb(255,255,255)`
  }

  ctx.beginPath()
  // ellipse(x, y, radiusX, radiusY, rotation, startAngle, endAngle)
  ctx.ellipse(
    sprite.positionX,
    sprite.positionY,
    sprite.width,
    sprite.height,
    0,
    0,
    Math.PI * 2,
    false
  )
  ctx.fill()
  ctx.stroke()
}

const updateNote = (sprite: Note) => {
  sprite.positionY += sprite.speed
  sprite.speed += sprite.acceleration
}

export const initNote = (options?: Partial<Note>): Note => {
  return {
    spawnTime: options?.spawnTime || 0,
    speed: options?.speed || 0,
    positionX: options?.positionX || 0,
    positionY: options?.positionY || 0,
    rgb: options?.rgb || [0, 0, 0],
    height: options?.height || SPRITE_HEIGHT,
    width: options?.width || SPRITE_WIDTH,
    revealed: options?.revealed || false,
    noteValue: options?.noteValue || 'C',
    acceleration: options?.acceleration || 0,
    draw: drawNote,
    update: updateNote,
  } as Note
}

const drawTextSprite = (
  sprite: TextSprite,
  ctx: CanvasRenderingContext2D
): void => {
  ctx.fillStyle = `rgb(${sprite.rgb[0]},${sprite.rgb[1]},${sprite.rgb[2]})`
  ctx.font = `${sprite.height}px Helvetica`

  let textMetrics = ctx.measureText(sprite.textValue)
  let xPos = sprite.positionX
  let yPos = sprite.positionY

  if (sprite.centered) {
    xPos = xPos - textMetrics.width / 2
  }

  if (sprite.positionX > CANVAS_WIDTH - textMetrics.width) {
    xPos = CANVAS_WIDTH - textMetrics.width
  }

  ctx.fillText(sprite.textValue, xPos, yPos)
}

const updateTextSprite = (sprite: TextSprite) => {
  sprite.positionY += sprite.speed
}

export const initTextSprite = (options?: Partial<TextSprite>): TextSprite => {
  return {
    spawnTime: options?.spawnTime || 0,
    speed: options?.speed || 0,
    positionX: options?.positionX || 0,
    positionY: options?.positionY || 0,
    rgb: options?.rgb || [0, 0, 0],
    height: options?.height || SPRITE_HEIGHT,
    textValue: options?.textValue || '',
    centered: options?.centered || false,
    draw: drawTextSprite,
    update: updateTextSprite,
  } as TextSprite
}

let drawCorrectSprite = (
  sprite: CorrectSprite,
  ctx: CanvasRenderingContext2D
): void => {
  ctx.strokeStyle = `rgb(${sprite.rgb[0]},${sprite.rgb[1]},${sprite.rgb[2]})`
  ctx.beginPath()
  ctx.lineWidth = 6
  ctx.arc(sprite.positionX, sprite.positionY, sprite.radius, 0, 2 * Math.PI)
  ctx.stroke()
  ctx.lineWidth = 1
}
let updateCorrectSprite = (sprite: CorrectSprite) => {
  sprite.radius +=
    ((sprite.speed * (RADIUS_THRESHOLD - sprite.radius)) / RADIUS_THRESHOLD) * 3
  sprite.positionY += sprite.speed
}

export const initCorrectSprite = (
  options?: Partial<CorrectSprite>
): CorrectSprite => {
  return {
    spawnTime: options?.spawnTime || 0,
    speed: options?.speed || 0,
    positionX: options?.positionX || 0,
    positionY: options?.positionY || 0,
    rgb: options?.rgb || [0, 0, 0],
    radius: options?.radius || 30,
    draw: drawCorrectSprite,
    update: updateCorrectSprite,
  } as CorrectSprite
}
