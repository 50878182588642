import { useRef } from 'react'
import Countdown from 'react-countdown'
import {
  ALLTIME_BEST_STREAK_LABEL,
  AVERAGE_BEST_STREAK_LABEL,
  AVERAGE_PERCENTAGE_LABEL,
  FIRST_ATTEMPTS_LABEL,
  NEXT_SHOWER_TEXT,
  RESET_STATS_LABEL,
  SHARE_TEXT,
} from '../../constants/strings'
import { AllTimeStats } from '../../lib/gameData'
import { getGameLogic } from '../../lib/gameLogic'
import { tomorrow } from '../../lib/melodies'
import { shareAllTimeStatus } from '../../lib/share'

type Props = {
  allTime: AllTimeStats
  height?: number
  handleShare: () => void
  handleReset: () => void
}

export const AllTimeModal = ({
  allTime,
  height,
  handleShare,
  handleReset,
}: Props) => {
  const buttonNode = useRef<HTMLButtonElement | null>(null)
  let averageFirstAttemptBestStreak: number
  let averageFirstAttemptPercent: number
  let allTimeFirstAttemptBestStreak: number

  if (!allTime.averageFirstAttempt) {
    averageFirstAttemptBestStreak = 0
    averageFirstAttemptPercent = 0
    allTimeFirstAttemptBestStreak = 0
  } else {
    averageFirstAttemptBestStreak = allTime.averageFirstAttempt.bestStreak
    averageFirstAttemptPercent = allTime.averageFirstAttempt.percentage
    allTimeFirstAttemptBestStreak = allTime.firstAttemptBestStreak
  }

  return (
    <div className="flex flex-col justify-between" style={{ height: height }}>
      <section className="py-4">
        <div className="flex justify-between pb-4">
          <p className="pt-1 font-bold">{FIRST_ATTEMPTS_LABEL}</p>
          <button
            ref={buttonNode}
            type="button"
            className="rounded-md border shadow-sm text-xs px-2 py-1 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500     border-slate-200      hover:bg-red-100 "
            onClick={() => {
              getGameLogic().clearStatsAndSession()
              handleReset()
            }}
          >
            {RESET_STATS_LABEL}
          </button>
        </div>

        <div className="flex relative text-sm xs:text-base">
          <div className="flex flex-col w-[40px]">
            <div className="flex grow justify-center">
              <div className="grow"></div>
              <div className="flex flex-col grow">
                <div className="grow-[1] border-l-2 border-b-2 border-inset border-black">
                  <span></span>
                </div>
                <div className="grow-[2] border-l-2 border-b-2 border-inset border-black">
                  <span></span>
                </div>
                <div className="grow-[2] border-l-2 border-b-2 border-inset border-black">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div className="relative top-[10px] align-bottom h-[150px] pl-3 flex flex-col justify-around grow">
            <div className="relative align-bottom grow-[1] text-left">
              <span className="absolute bottom-0">
                {AVERAGE_PERCENTAGE_LABEL}{' '}
                {averageFirstAttemptPercent.toFixed(1) + '%'}
              </span>
            </div>
            <div className="relative grow-[2] text-left">
              <span className="absolute bottom-0">
                {AVERAGE_BEST_STREAK_LABEL}{' '}
                {averageFirstAttemptBestStreak.toFixed(1)}
              </span>
            </div>
            <div className="relative grow-[2] text-left">
              <span className="absolute bottom-0">
                {ALLTIME_BEST_STREAK_LABEL} {allTimeFirstAttemptBestStreak}
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="text-xs xs:text-base py-4 flex justify-between dark:text-white">
        <div>
          <h5>{NEXT_SHOWER_TEXT}</h5>
          <Countdown
            className="font-medium text-gray-900 dark:text-gray-100"
            date={tomorrow}
            daysInHours={true}
          />
        </div>
        <button
          id="share_alltime"
          ref={buttonNode}
          type="button"
          className="rounded-md border border-transparent shadow-sm px-1 xs:px-4 py-1 xs:py-2 text-base font-medium focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm   bg-blue-600   text-white hover:bg-blue-700 "
          onClick={() => {
            shareAllTimeStatus(
              averageFirstAttemptPercent.toFixed(1),
              averageFirstAttemptBestStreak.toFixed(1),
              allTimeFirstAttemptBestStreak
            )
            handleShare()
          }}
        >
          {SHARE_TEXT}
        </button>
      </section>
    </div>
  )
}
