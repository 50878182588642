/*
  The canvas component is a generic wrapper over an html canvas element which allows a parent to dictate:
  - what/how the canvas draws via a "draw" function
  - when the canvas draws via an "onMount" function
  
  A benefit of this model is that the parent maintains a closure of variables that can be referenced within the draw declaration
*/

import React, { forwardRef, useEffect, useState } from 'react'

type CanvasProps = React.DetailedHTMLProps<
  React.CanvasHTMLAttributes<HTMLCanvasElement>,
  HTMLCanvasElement
> & {
  draw: (context: CanvasRenderingContext2D) => void
  height: number
  width: number
  pixelRatioAdjustment?: number
  onMount?: (setter: React.Dispatch<React.SetStateAction<number>>) => void
}

const Canvas = forwardRef<HTMLCanvasElement, CanvasProps>(
  (
    { draw, height, width, pixelRatioAdjustment, onMount, ...props },
    canvasRef
  ) => {
    const [newFrame, setNewFrame] = useState(0)
    useEffect(() => {
      // if onMount, we allow parent to choose when this component re-renders via setValue
      // if !onMount, we allow parent re-rendering to trigger draw functionality
      if (onMount) {
        onMount(setNewFrame)
      }
    }, [onMount])

    useEffect(() => {
      if (!canvasRef) {
        return
      }

      const canvas = (canvasRef as React.RefObject<HTMLCanvasElement>).current
      if (!canvas) {
        return
      }

      const context = canvas.getContext('2d')
      if (!context) {
        return
      }

      draw(context)
      return () => context.clearRect(0, 0, width, height)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draw, newFrame, canvasRef, width, height])

    if (!canvasRef) {
      return null
    }

    return (
      <canvas
        className="mx-auto"
        width={width}
        height={height}
        style={{
          height: pixelRatioAdjustment ? height / pixelRatioAdjustment : height,
          width: pixelRatioAdjustment ? width / pixelRatioAdjustment : width,
        }}
        ref={canvasRef as React.RefObject<HTMLCanvasElement>}
        {...props}
      />
    )
  }
)

export default Canvas
