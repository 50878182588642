export type CharValue =
  | 'C'
  | 'C#'
  | 'D'
  | 'D#'
  | 'E'
  | 'F'
  | 'F#'
  | 'G'
  | 'G#'
  | 'A'
  | 'A#'
  | 'B'

export type KeyValue = CharValue | 'LAST_NOTE_PLAYBACK'

export const KEYBOARD_MAP: { [key: string]: string } = {
  q: 'C',
  w: 'C#',
  e: 'D',
  r: 'D#',
  t: 'E',
  y: 'F',
  u: 'F#',
  i: 'G',
  o: 'G#',
  p: 'A',
  '[': 'A#',
  ']': 'B',
}
