import { Statline } from '../../lib/Statline'
import { StatLineView } from '../StatLineView'
import { Achievement, ACHIEVEMENT_KEY, TodayStats } from '../../lib/gameData'
import { FaCloud } from 'react-icons/fa'
import { GiHeavyRain, GiPowerLightning } from 'react-icons/gi'
import {
  NEXT_SHOWER_TEXT,
  ordinal,
  SHARE_TEXT,
  PLAY_TEXT,
  REPLAY_TEXT,
  RAIN_BADGE_LABEL,
  RAIN_BADGE_DESCRIPTION,
  CLOUD_BADGE_LABEL,
  CLOUD_BADGE_DESCRIPTION,
  LIGHTNING_BADGE_LABEL,
  LIGHTNING_BADGE_DESCRIPTION,
  NOT_YET_PLAYED,
  ACHIEVEMENT_LABEL,
  ATTEMPT_LABEL,
  ATTEMPT_DESCRIPTION,
  FOUND_DESCRIPTION,
  FOUND_LABEL,
  CORRECT_LABEL,
  CORRECT_DESCRIPTION,
  BEST_STREAK_LABEL,
} from '../../constants/strings'
import { shareTodayStatus } from '../../lib/share'
import { tomorrow } from '../../lib/melodies'
import Countdown from 'react-countdown'
import { useEffect, useRef } from 'react'

type Props = {
  today: TodayStats
  attempts: number
  handleShare: () => void
  invokeParentCallback: (param: number) => void
  handleReplay: () => void
  isPlaying: boolean
}

export const TodayModal = ({
  today,
  attempts,
  handleShare,
  invokeParentCallback,
  isPlaying,
  handleReplay,
}: Props) => {
  const buttonNode = useRef<HTMLButtonElement | null>(null)
  const containerNode = useRef<HTMLDivElement | null>(null)

  let lastAttemptLine: Statline
  let firstAttemptLine: Statline
  let achievements: { [key in ACHIEVEMENT_KEY]: Achievement }
  let todayAttemptCount: number = today ? today.totalAttempts : 0

  if (!today?.lastAttempt || !today?.firstAttempt) {
    lastAttemptLine = { found: 0, percentage: 0, bestStreak: 0 }
    firstAttemptLine = { found: 0, percentage: 0, bestStreak: 0 }
    achievements = {
      found100: {
        achieved: false,
        attemptNumber: 0,
      },
      streakHit: {
        achieved: false,
        attemptNumber: 0,
      },
      correct100: {
        achieved: false,
        attemptNumber: 0,
      },
    }
  } else {
    lastAttemptLine = today.lastAttempt
    firstAttemptLine = today.firstAttempt
    achievements = today.achievements
  }

  let hoverClassTop =
    'w-32 font-light absolute left-[50%] translate-x-[-50%] py-2 px-2 text-xs bg-blue-100 hidden text-center z-50 border rounded -mt-14 group-hover:block'
  let hoverClassBottom =
    'w-32 font-light absolute left-[50%] translate-x-[-50%] py-2 px-2 text-xs bg-blue-100 hidden text-center z-50 border rounded -mb-14 group-hover:block'
  let achievedStyle = 'bg-blue-200 border-2 border-blue-600'
  let found100achieved = today?.achievements['found100'].achieved
  let streakHitachieved = today?.achievements['streakHit'].achieved
  let correct100achieved = today?.achievements['correct100'].achieved

  useEffect(() => {
    buttonNode!.current?.focus()
  }, [])

  useEffect(() => {
    invokeParentCallback(containerNode?.current!.clientHeight)
  }, [attempts])

  return (
    <div ref={containerNode}>
      <section className="py-4">
        {todayAttemptCount === 0 ? (
          <div className="py-4">
            <span>{NOT_YET_PLAYED}</span>
          </div>
        ) : (
          <table className="w-full border-collapse">
            <thead>
              <tr className="border-collapse">
                <th className="group relative">
                  <span className="text-xs p-0 xs:p-2">{ATTEMPT_LABEL}</span>
                  <span className={hoverClassBottom}>
                    {ATTEMPT_DESCRIPTION}
                  </span>
                </th>
                <th className="group relative">
                  <span className="text-xs p-0 xs:p-2">{FOUND_LABEL}</span>
                  <span className={hoverClassBottom}>{FOUND_DESCRIPTION}</span>
                </th>
                <th className="group relative">
                  <span className="text-xs p-0 xs:p-2">
                    {BEST_STREAK_LABEL}
                  </span>
                </th>
                <th className="group relative">
                  <span className="text-xs p-0 xs:p-2">{CORRECT_LABEL}</span>
                  <span className={hoverClassBottom}>
                    {CORRECT_DESCRIPTION}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <StatLineView
                line={lastAttemptLine}
                title={`#${today ? today.totalAttempts : 0}`}
                highlight={true}
              />
              {todayAttemptCount <= 1 ? (
                ''
              ) : (
                <StatLineView line={firstAttemptLine} title="#1" />
              )}
            </tbody>
          </table>
        )}
      </section>
      <section className="text-left py-4">
        <span className="font-bold">{ACHIEVEMENT_LABEL}</span>
        <div className="py-4 flex w-[80%] justify-center mx-auto">
          <div className="flex flex-col px-2 group relative">
            <div
              className={`${
                found100achieved ? achievedStyle : 'bg-gray-100'
              } rounded-full w-[60px] h-[60px] flex items-center justify-center`}
            >
              <FaCloud
                size="2em"
                color={found100achieved ? 'rgb(12 74 110)' : 'rgb(230 230 230)'}
              />
            </div>
            <span className="text-center text-xs">
              {found100achieved
                ? `${ordinal(
                    today?.achievements['found100'].attemptNumber as number
                  )} go`
                : ''}
            </span>

            <span className={hoverClassTop}>
              {CLOUD_BADGE_LABEL}
              <br />
              {CLOUD_BADGE_DESCRIPTION}
            </span>
          </div>

          <div className="flex flex-col px-2 group relative">
            <div
              className={`${
                streakHitachieved ? achievedStyle : 'bg-gray-100'
              } rounded-full w-[60px] h-[60px] flex items-center justify-center`}
            >
              <GiHeavyRain
                size="2em"
                color={
                  streakHitachieved ? 'rgb(6 182 212)' : 'rgb(230 230 230)'
                }
              />
            </div>
            <span className="text-center text-xs">
              {streakHitachieved
                ? `${ordinal(
                    today?.achievements['streakHit'].attemptNumber as number
                  )} go`
                : ''}
            </span>
            <span className={hoverClassTop}>
              {RAIN_BADGE_LABEL}
              <br></br>
              {RAIN_BADGE_DESCRIPTION}
            </span>
          </div>

          <div className="flex flex-col px-2 group relative">
            <div
              className={`${
                correct100achieved ? achievedStyle : 'bg-gray-100'
              } rounded-full w-[60px] h-[60px] flex items-center justify-center`}
            >
              <GiPowerLightning
                size="2em"
                color={
                  correct100achieved ? 'rgb(234 179 8)' : 'rgb(230 230 230)'
                }
              />
            </div>
            <span className="text-center text-xs">
              {correct100achieved
                ? `${ordinal(
                    today?.achievements['correct100'].attemptNumber as number
                  )} go`
                : ''}
            </span>
            <span className={hoverClassTop}>
              {LIGHTNING_BADGE_LABEL}
              <br />
              {LIGHTNING_BADGE_DESCRIPTION}
            </span>
          </div>
        </div>
      </section>
      <section className="text-xs xs:text-base py-4 flex justify-between dark:text-white">
        <div>
          <h5>{NEXT_SHOWER_TEXT}</h5>
          <Countdown
            className="font-medium text-gray-900 dark:text-gray-100"
            date={tomorrow}
            daysInHours={true}
          />
        </div>

        {isPlaying ? (
          ''
        ) : (
          <button
            type="button"
            className="rounded-md border border-transparent shadow-sm px-1 xs:px-4 py-1 xs:py-2 text-base font-medium focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm      border-blue-100      hover:bg-blue-100 "
            onClick={handleReplay}
          >
            {todayAttemptCount === 0 ? PLAY_TEXT : REPLAY_TEXT}
          </button>
        )}

        <button
          id="share_today"
          ref={buttonNode}
          type="button"
          className="rounded-md border border-transparent shadow-sm px-1 xs:px-4 py-1 xs:py-2 text-base font-medium focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm   bg-blue-600   text-white hover:bg-blue-700 "
          onClick={() => {
            shareTodayStatus(
              lastAttemptLine,
              todayAttemptCount,
              today.achievements
            )
            handleShare()
          }}
        >
          {SHARE_TEXT}
        </button>
      </section>
    </div>
  )
}
