import React, { useEffect } from 'react'
import { BaseModal } from './BaseModal'
import { AllTimeStats, TodayStats } from '../../lib/gameData'
import { TodayModal } from './TodayModal'
import { AllTimeModal } from './AllTimeModal'
import {
  ALLTIME_COPIED_MESSAGE,
  GAME_COPIED_MESSAGE,
  GAME_STATS_RESET_MESSAGE,
} from '../../constants/strings'

type Props = {
  title: string
  isOpen: boolean
  handleClose: () => void
  replay: () => void
  handleNotification: (text: string) => () => void
  today: TodayStats
  allTime: AllTimeStats
  isPlaying: boolean
}

export const StatsModal = ({
  title,
  isOpen,
  handleClose,
  replay,
  handleNotification,
  today,
  allTime,
  isPlaying,
}: Props) => {
  const [isTodaySelected, setIsTodaySelected] = React.useState<boolean>(true)
  const [allTimeHeight, setAllTimeHeight] = React.useState<number>(0)

  useEffect(() => {
    setTimeout(selectToday, 300)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const selectToday = () => {
    if (!isTodaySelected) {
      setIsTodaySelected(true)
    }
  }

  const selectAlltime = () => {
    if (isTodaySelected) {
      setIsTodaySelected(false)
    }
  }

  const invokeParentCallback = (modalHeight: number) => {
    setAllTimeHeight(modalHeight)
  }

  const handleReplay = () => {
    handleClose()
    replay()
  }

  return (
    <BaseModal title={title} isOpen={isOpen} handleClose={handleClose}>
      <section className="p-1 flex justify-between w-full">
        <button
          onClick={selectToday}
          className={`grow pb-2 font-bold text-center ${
            isTodaySelected ? 'border-b-2 border-black' : ''
          }`}
        >
          Today
        </button>
        <button
          onClick={selectAlltime}
          className={`grow pb-2 font-bold text-center ${
            !isTodaySelected ? 'border-b-2 border-black' : ''
          }`}
        >
          All-Time
        </button>
      </section>

      <section className="p-1">
        {isTodaySelected ? (
          <TodayModal
            today={today}
            attempts={today.totalAttempts}
            handleShare={handleNotification(GAME_COPIED_MESSAGE)}
            handleReplay={handleReplay}
            invokeParentCallback={invokeParentCallback}
            isPlaying={isPlaying}
          />
        ) : (
          <AllTimeModal
            height={allTimeHeight}
            allTime={allTime}
            handleShare={handleNotification(ALLTIME_COPIED_MESSAGE)}
            handleReset={handleNotification(GAME_STATS_RESET_MESSAGE)}
          />
        )}
      </section>
      <div className="text-slate-400">
        <p className="text-xs">
          Switch it up? Try a different Daily Jingle{' '}
          <a
            className="focus:outline-none font-bold"
            href="https://classic.dailyjingle.me/"
          >
            here!
          </a>
        </p>
      </div>
    </BaseModal>
  )
}
