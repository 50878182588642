import { getGameLogic } from '../../lib/gameLogic'

let classList: string[]
export const FoundKeeper = () => {
  classList = ['flex flex-col justify-center items-center h-full p-1']
  return (
    <div className={classList.join(' ')}>
      <span className="font-bold">FOUND</span>
      <span className="">{`${getGameLogic().getFoundCount()}`}</span>
    </div>
  )
}
