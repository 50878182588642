export const KeyboardView = () => {
  return (
    <div className="text-xs w-full flex justify-around bottom-0">
      <div className="flex flex-col">
        <span className="font-bold">C</span>
        <span>q</span>
      </div>
      <div className="flex flex-col">
        <span className="font-bold">C#</span>
        <span>w</span>
      </div>
      <div className="flex flex-col">
        <span className="font-bold">D</span>
        <span>e</span>
      </div>
      <div className="flex flex-col">
        <span className="font-bold">D#</span>
        <span>r</span>
      </div>
      <div className="flex flex-col">
        <span className="font-bold">E</span>
        <span className="text-center">t</span>
      </div>
      <div className="flex flex-col">
        <span className="font-bold">F</span>
        <span className="text-center">y</span>
      </div>
      <div className="flex flex-col">
        <span className="font-bold">F#</span>
        <span className="text-center">u</span>
      </div>
      <div className="flex flex-col">
        <span className="font-bold">G</span>
        <span className="text-center">i</span>
      </div>
      <div className="flex flex-col">
        <span className="font-bold">G#</span>
        <span className="text-center">o</span>
      </div>
      <div className="flex flex-col">
        <span className="font-bold">A</span>
        <span className="text-center">p</span>
      </div>
      <div className="flex flex-col">
        <span className="font-bold">A#</span>
        <span className="text-center">[</span>
      </div>
      <div className="flex flex-col">
        <span className="font-bold">B</span>
        <span className="text-center">]</span>
      </div>
    </div>
  )
}
