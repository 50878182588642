import { getGameLogic } from '../../lib/gameLogic'

let current: number
let best: number

export const StreakKeeper = () => {
  current = getGameLogic().getCurrentStreak()
  best = getGameLogic().getBestStreak()

  return (
    <>
      <div className="flex flex-col justify-center items-center h-full p-1">
        <span className="font-bold">STREAK</span>
        <span>{current}</span>
      </div>
      <div className="flex flex-col justify-center items-center h-full p-1">
        <span className="font-bold">BEST STREAK</span>
        <span>{best}</span>
      </div>
    </>
  )
}
