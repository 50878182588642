/*
  The Stage component primarily manages game animation logic via requestAnimationFrame:
  - use 'clock' to calculate whether new frames should be handled and rendered
  - invoke scoreboard + game completion callbacks as decided by gameLogic dependency
  - declare and invoke draw functionality via child Canvas wrapper component

  The closure of "drawGame" allows Stage to manage game state and logic without needing to pass context to the child Canvas wrapper
*/

import React, { useRef, Dispatch, SetStateAction, useCallback } from 'react'
import Canvas from '../components/Canvas'
import { getGameLogic } from '../lib/gameLogic'
import {
  FPS,
  getCurrentFrame,
  getFramesToRender,
  incrementFrame,
} from '../lib/frameLogic'
import {
  CANVAS_WIDTH,
  CANVAS_HEIGHT,
  STATUS,
  PAUSE_THRESHOLD,
  PIXEL_RATIO_ADJUSTMENT,
} from '../lib/gameParams'
import { PLAY_TEXT, REPLAY_TEXT } from '../constants/strings'

type Props = {
  isPlaying: boolean
  isGameComplete: boolean
  onStart: () => void
  invokeScorebarUpdate: () => void
  invokeGameCompletion: () => void
}

export const Stage = ({
  isPlaying,
  isGameComplete,
  onStart,
  invokeScorebarUpdate,
  invokeGameCompletion,
}: Props) => {
  /* 
  - onCanvasMount allows for the child Canvas wrapper to pass a state setter to Stage (stored in "setNewCanvasFrame" ref)
  - setNewCanvasFrame is used to allow Stage component to render Canvas (and trigger draw) on command
  Pattern is used for performance reasons and to maintain separation of concern:
  */
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const setNewCanvasFrame = useRef<Dispatch<SetStateAction<number>>>()
  const onCanvasMount = useCallback(
    (canvasNewFrameSetter: Dispatch<SetStateAction<number>>) => {
      setNewCanvasFrame.current = canvasNewFrameSetter
    },
    []
  )

  React.useLayoutEffect(() => {
    let rafRef: number
    let clockRef: number
    let framesToRender: number
    let status: STATUS
    let notesProducedRef: number

    const animate = (clock: number) => {
      if (clockRef === undefined) {
        clockRef = clock
        rafRef = requestAnimationFrame(animate)
        return
      }

      let deltaTime: number = clock - clockRef!
      framesToRender = getFramesToRender(deltaTime)
      // if frames to render is less than 1, we do not need to render
      if (framesToRender < 1 || framesToRender > FPS * PAUSE_THRESHOLD) {
        // if last raf not called in over 3 seconds, effectively pause game
        clockRef = clock
        rafRef = requestAnimationFrame(animate)
        return
      }

      let shouldInvokeScorebar = false

      while (framesToRender > 0) {
        status = 'EMPTY'
        incrementFrame()

        if (isPlaying && !isGameComplete) {
          status = getGameLogic().handleNewFrameAndReturnStatus(
            getCurrentFrame()
          )
          if (
            status !== 'EMPTY' ||
            notesProducedRef !== getGameLogic().getNotesProducedCount()
          ) {
            shouldInvokeScorebar = true
          }
        } else {
          getGameLogic().runStandby()
        }

        framesToRender--
      }

      // allow Stage to update scorebar, managed by parent
      if (shouldInvokeScorebar) {
        invokeScorebarUpdate()
        notesProducedRef = getGameLogic().getNotesProducedCount()
      }

      if (isPlaying && !isGameComplete) {
        if (
          getGameLogic().allNotesPlayed() &&
          getGameLogic().gameBoardClear()
        ) {
          invokeGameCompletion()
          return
        }
      }
      rafRef = requestAnimationFrame(animate)
      clockRef = clock

      if (setNewCanvasFrame) {
        setNewCanvasFrame.current!(clock) // re-render canvas (if ready)
      }
    }

    rafRef = requestAnimationFrame(animate)

    return () => {
      cancelAnimationFrame(rafRef)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isPlaying, isGameComplete])
  }, [isPlaying, isGameComplete, invokeGameCompletion, invokeScorebarUpdate])

  const drawGame = useCallback(
    (ctx: CanvasRenderingContext2D) => {
      if (!isPlaying) {
        getGameLogic()
          .getStandbyNotes()
          .forEach((sprite) => {
            sprite.draw(sprite, ctx)
          })
        return
      }

      getGameLogic()
        .getStageNotes()
        .forEach((sprite) => {
          sprite.draw(sprite, ctx)
        })

      getGameLogic()
        .getFinishedNotes()
        .forEach((sprite) => {
          sprite.draw(sprite, ctx)
        })

      getGameLogic()
        .getTextSprites()
        .forEach((sprite) => {
          sprite.draw(sprite, ctx)
        })

      getGameLogic()
        .getCorrectSprites()
        .forEach((sprite) => {
          sprite.draw(sprite, ctx)
        })
    },
    [isPlaying]
  )

  return (
    <div className="grow relative flex flex-col">
      <>
        <div className={`grow-[1] overflow-hidden top-0 relative`}>
          <div className="bg-gray-100 w-full h-3/4 top-0 absolute"></div>
          <div className="bg-gray-100 w-[20%] h-[75%] top-[12%] left-[-3%] rounded-full absolute"></div>
          <div className="bg-gray-100 w-[25%] h-[85%] top-[15%] left-[9%] rounded-full absolute"></div>
          <div className="bg-gray-100 w-[20%] h-[75%] top-[12%] left-[30%] rounded-full absolute"></div>
          <div className="bg-gray-100 w-[20%] h-[75%] top-[12%] left-[45%] rounded-full absolute"></div>
          <div className="bg-gray-100 w-[28%] h-[85%] top-[15%] left-[60%] rounded-full absolute"></div>
          <div className="bg-gray-100 w-[20%] h-[75%] top-[12%] left-[85%] rounded-full absolute"></div>
        </div>

        <div className="w-full font-bold p-3 h-13 text-center absolute top-1/2">
          <button
            onClick={onStart}
            className={`p-3 font-bold border solid rounded-lg hover:bg-blue-100 ${
              !isPlaying ? 'show' : 'hidden'
            }`}
          >
            {getGameLogic().getAttemptNumber() === 0 ? PLAY_TEXT : REPLAY_TEXT}
          </button>
        </div>

        <Canvas
          height={CANVAS_HEIGHT}
          width={CANVAS_WIDTH}
          ref={canvasRef}
          draw={drawGame}
          pixelRatioAdjustment={PIXEL_RATIO_ADJUSTMENT}
          onMount={onCanvasMount}
        />
        <div className="flex bg-gray-100 grow-[1]"></div>
      </>
    </div>
  )
}
