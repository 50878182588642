import seedrandom from 'seedrandom'
import { getDayIndex } from './gameData'
import { EPOCH_MS, MS_IN_DAY } from '../constants/numbers'

import { NOTES_PER_GAME } from './gameParams'

const mode = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B']

const generateMelody = (length: number, seed: number): string[] => {
  let generatedMelody = []
  let index
  let rng: seedrandom.PRNG
  let seedStr: string

  for (let i = 0; i < length; i++) {
    seedStr = (
      seed +
      ':' +
      i +
      "You found the solution to every solution! Now that you've won your game, please allow others to enjoy theirs!"
    ).toString()

    rng = seedrandom(seedStr)
    index = Math.floor(rng() * mode.length)
    generatedMelody.push(mode[index])
  }

  return generatedMelody
}

export const getMelodyShowerOfDay = () => {
  const index = getDayIndex()
  const nextday = (index + 1) * MS_IN_DAY + EPOCH_MS
  const generatedMelody = generateMelody(NOTES_PER_GAME, index)

  return {
    solution: generatedMelody,
    solutionIndex: index,
    tomorrow: nextday,
  }
}

export const { solution, solutionIndex, tomorrow } = getMelodyShowerOfDay()
