import {
  CorrectSprite,
  initCorrectSprite,
  initNote,
  initTextSprite,
  MoveableCanvasSprite,
  Note,
  TextSprite,
} from '../lib/StageObjects'
import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  COLORMAP,
  SPRITE_HEIGHT,
  SPRITE_WIDTH,
  TTL_FINISHED_SPRITE,
  NOTES_PER_GAME,
  INITIAL_SECONDS_BEFORE_NEXT_NOTE,
  SCALE,
  BUFFER_BETWEEN_CORRECT_NOTES,
  NOTE_SPEED,
  STATUS,
} from '../lib/gameParams'
import { FPS, getCurrentFrame, initFrameLogic } from './frameLogic'
import {
  logStatline,
  StoredGameData,
  getDayIndex,
  getGameData,
  clearCache,
} from './gameData'
import { Statline } from './Statline'
import { solution } from '../lib/melodies'
import { getAudioHelper } from './AudioHelper'
import { clearStorageAndCache } from './localStorage'

let notesProduced: number
let framesUntilNextNote: number
let lastNoteFrame: number
let framesElapsed: number
let currentStreak: number
let bestStreak: number
let guessCount: number
let foundCount: number
let missedNotes: string[]
let lostCount: number
let attemptNumber: number
let stageNotes: Note[]
let standbyNotes: Note[]
let finishedNotes: Note[]
let textSprites: TextSprite[]
let correctSprites: CorrectSprite[]
let gameData: StoredGameData
let gameLogic: GameLogic

const start = () => {
  getAudioHelper().playEmptyNote()
  initFrameLogic()
  stageNotes = []
  finishedNotes = []
  textSprites = []
  correctSprites = []
  standbyNotes = []
  missedNotes = []
  notesProduced = 0
  lastNoteFrame = getCurrentFrame()
  foundCount = 0
  lostCount = 0
  guessCount = 0
  bestStreak = 0
  currentStreak = 0
  initCountdown()
}

const clearStatsAndSession = () => {
  clearStorageAndCache()
  clearCache()
  resetAttemptNumber()
}

let countdownFinished: boolean
let countdownQueue: number[]

const initCountdown = (): void => {
  countdownFinished = false
  countdownQueue = [1, 2, 3]
}

const queueCountdownCount = (countdownQueue: number[]): void => {
  if (
    !countdownQueue ||
    countdownQueue.length === 0 ||
    !countdownQueue[countdownQueue.length - 1]
  ) {
    throw Error(
      'queueCountdownCount called without a proper queue or proper queue element to queue'
    )
  }

  textSprites.push(
    initTextSprite({
      spawnTime: getCurrentFrame(),
      positionX: CANVAS_WIDTH / 2,
      positionY: CANVAS_HEIGHT / 2,
      height: SPRITE_HEIGHT * 3,
      rgb: [200, 200, 200],
      textValue: countdownQueue.pop()!.toString(),
      centered: true,
    })
  )
}

const incrementAttemptNumber = () => {
  attemptNumber += 1
}

const getAttemptNumber = (): number => {
  return attemptNumber
}

const resetAttemptNumber = () => {
  attemptNumber = 0
}

const updateGameDataWithFinishedGame = (line: Statline): void => {
  gameData = getGameData(getDayIndex())
  incrementAttemptNumber()
  logStatline(line, gameData, attemptNumber, getDayIndex())
}

const newNoteIsReady = (currentFrame: number): boolean => {
  framesElapsed = currentFrame - lastNoteFrame
  if (
    stageNotes.length === 0 &&
    framesElapsed > framesUntilNextNote &&
    notesProduced < NOTES_PER_GAME
  ) {
    return true
  }
  return false
}

const allNotesPlayed = (): boolean => {
  if (notesProduced >= NOTES_PER_GAME) {
    return true
  }
  return false
}

const getSolution = (): string[] => {
  return [...solution]
}

const gameBoardClear = (): boolean => {
  return (
    textSprites.length === 0 &&
    stageNotes.length === 0 &&
    finishedNotes.length === 0
  )
}

let noteVal: string
const renderNewNote = (frame: number): Note => {
  noteVal = produceNote()
  stageNotes.push(
    initNote({
      spawnTime: frame,
      noteValue: noteVal,
      speed: (NOTE_SPEED * SCALE) / speedModifier(currentStreak),
      rgb: [...COLORMAP[noteVal]],
      positionX:
        3 * SPRITE_WIDTH + Math.random() * (CANVAS_WIDTH - 6 * SPRITE_WIDTH),
      revealed: false,
    })
  )

  return stageNotes[stageNotes.length - 1]
}

const createCorrectSprite = (n: Note): CorrectSprite => {
  return initCorrectSprite({
    spawnTime: n.revealTime!,
    positionY: n.positionY,
    positionX: n.positionX,
    speed: n.speed,
    rgb: [...COLORMAP[n.noteValue]],
  })
}

let i: number
const revealMatchesOnStage = (noteVal: string, stageNotes: Note[]): void => {
  for (i = 0; i < stageNotes.length; i++) {
    if (stageNotes[i].noteValue === noteVal) {
      stageNotes[i].revealed = true
      stageNotes[i].revealTime = getCurrentFrame()
    }
  }
}

let status: STATUS
let filteredNotes: Note[]
const handleKeynoteAndReturnStatus = (keyVal: string): STATUS => {
  status = 'MISS'
  filteredNotes = []

  revealMatchesOnStage(keyVal, stageNotes)

  for (let i = 0; i < stageNotes.length; i++) {
    if (stageNotes[i].revealed) {
      status = 'FOUND'
      incrementFoundCount()
      finishedNotes.push({
        ...stageNotes[i],
        spawnTime: stageNotes[i].revealTime!,
      })
      correctSprites.push(createCorrectSprite({ ...stageNotes[i] }))
      framesUntilNextNote =
        getCurrentFrame() - lastNoteFrame + FPS * BUFFER_BETWEEN_CORRECT_NOTES
    } else {
      filteredNotes.push(stageNotes[i])
    }
  }

  if (status === 'FOUND') {
    incrementCurrentStreak()

    if (currentStreak > bestStreak) {
      bestStreak = currentStreak
    }
    incrementGuessCount()
  } else if (status === 'MISS') {
    logMissedNote(noteVal)
    resetCurrentStreak()
    incrementGuessCount()
  } else {
    status = 'EMPTY'
    console.log('no-op keynote status')
  }

  stageNotes = [...filteredNotes]
  return status
}

const handleBoundsAndReturnStatus = (): STATUS => {
  status = 'EMPTY'
  filteredNotes = []

  for (i = 0; i < stageNotes.length; i++) {
    if (stageNotes[i].positionY + SPRITE_HEIGHT > CANVAS_HEIGHT) {
      logMissedNote(stageNotes[i].noteValue)
      resetCurrentStreak()
      incrementGuessCount()
      status = 'LOST'
      incrementLostCount()
    } else {
      filteredNotes.push(stageNotes[i])
    }
  }

  stageNotes = [...filteredNotes]
  return status
}

const logMissedNote = (val: string): void => {
  missedNotes.push(val)
}

const getMissedNotes = (): string[] => {
  return [...new Set(missedNotes)]
}

const getCurrentStreak = (): number => {
  return currentStreak
}

const incrementCurrentStreak = (): void => {
  currentStreak += 1
}

const resetCurrentStreak = (): void => {
  currentStreak = 0
}

const getFoundCount = (): number => {
  return foundCount
}

const getNotesProducedCount = (): number => {
  return notesProduced
}

const getGameCompletionPercentage = (): number => {
  return (notesProduced / NOTES_PER_GAME) * 100
}

const incrementFoundCount = (): void => {
  foundCount += 1
}

const getGuessCount = (): number => {
  return guessCount
}

const incrementGuessCount = (): void => {
  guessCount += 1
}

const getLostCount = (): number => {
  return lostCount
}

const incrementLostCount = (): void => {
  lostCount += 1
}

const getBestStreak = (): number => {
  return bestStreak
}

const getPercentage = (): number => {
  if (guessCount > 0) return (foundCount / guessCount) * 100
  return 0
}

const getStageNotes = (): Note[] => {
  return stageNotes ?? []
}

const getFinishedNotes = (): Note[] => {
  return finishedNotes ?? []
}

const getStandbyNotes = (): Note[] => {
  return standbyNotes ?? []
}

const getTextSprites = (): TextSprite[] => {
  return textSprites ?? []
}

const getCorrectSprites = (): CorrectSprite[] => {
  return correctSprites ?? []
}

let num: number
const invokeProbabilisticStandbyNote = () => {
  num = Math.random() * 100
  if (num > 90) {
    standbyNotes.push(
      initNote({
        speed: NOTE_SPEED * SCALE,
        rgb: [240, 240, 240],
        positionX:
          3 * SPRITE_WIDTH + Math.random() * (CANVAS_WIDTH - 6 * SPRITE_WIDTH),
        acceleration: 0.5 * SCALE,
        revealed: true,
      })
    )
  }
}

const handleStandbyBounds = () => {
  standbyNotes = standbyNotes.filter((note) => {
    if (note.positionY + SPRITE_HEIGHT > CANVAS_HEIGHT) {
      return false
    }
    return true
  })
}

const runStandby = () => {
  invokeProbabilisticStandbyNote()
  translateSprites(standbyNotes)
  handleStandbyBounds()
}

const playLastNote = (): void => {
  const now = getAudioHelper().getCurrentTime()

  if (stageNotes.length >= 1) {
    getAudioHelper().playNote(stageNotes[stageNotes.length - 1].noteValue, now)
  }
}

const playStartNote = (): void => {
  const now = getAudioHelper().getCurrentTime()
  getAudioHelper().playNote('C', now)
}

const translateSprites = (
  sprites: MoveableCanvasSprite[]
): MoveableCanvasSprite[] => {
  for (i = 0; i < sprites.length; i++) {
    sprites[i].update(sprites[i])
  }

  return sprites
}

const fadeSprites = (
  sprites: MoveableCanvasSprite[]
): MoveableCanvasSprite[] => {
  for (i = 0; i < sprites.length; i++) {
    sprites[i].rgb![0] += 3
    sprites[i].rgb![1] += 3
    sprites[i].rgb![2] += 3
  }
  return sprites
}

let filteredSprites: MoveableCanvasSprite[]
const removeStaleSprites = (sprites: MoveableCanvasSprite[]) => {
  filteredSprites = []

  for (i = 0; i < sprites.length; i++) {
    if (getCurrentFrame() - sprites[i].spawnTime < TTL_FINISHED_SPRITE) {
      filteredSprites.push(sprites[i])
    }
  }

  return [...filteredSprites]
}

let note: Note
const handleNewFrameAndReturnStatus = (currentFrame: number): STATUS => {
  if (!countdownFinished) {
    if (countdownQueue.length > 0 && textSprites.length === 0) {
      // queue next countdown note
      queueCountdownCount(countdownQueue)
      return 'EMPTY'
    } else if (textSprites.length > 0) {
      // check for stale sprites
      textSprites = removeStaleSprites(textSprites) as TextSprite[]
      return 'EMPTY'
    } else if (countdownQueue.length === 0 && textSprites.length === 0) {
      countdownFinished = true
      // set frame to next note
      framesUntilNextNote = FPS * BUFFER_BETWEEN_CORRECT_NOTES
      lastNoteFrame = getCurrentFrame()
      guessCount = 0
      missedNotes = []

      return 'EMPTY'
    }
  }

  stageNotes = translateSprites(stageNotes) as Note[]
  finishedNotes = translateSprites(finishedNotes) as Note[]
  correctSprites = translateSprites(correctSprites) as CorrectSprite[]

  finishedNotes = removeStaleSprites(finishedNotes) as Note[]
  correctSprites = removeStaleSprites(correctSprites) as CorrectSprite[]

  if (newNoteIsReady(currentFrame)) {
    note = renderNewNote(currentFrame)

    framesUntilNextNote =
      FPS * INITIAL_SECONDS_BEFORE_NEXT_NOTE * speedModifier(currentStreak)
    lastNoteFrame = currentFrame

    const now = getAudioHelper().getCurrentTime()
    getAudioHelper().playNote(note.noteValue, now)
  }
  return handleBoundsAndReturnStatus()
}

const isCountdownFinished = (): boolean => {
  return countdownFinished
}

const produceNote = (): string => {
  return solution[notesProduced++]
}

const speedModifier = (currentStreak: number): number => {
  if (currentStreak < NOTES_PER_GAME) {
    return 1 - 0.0587 * currentStreak
  }

  return 0.1
}

export type GameLogic = {
  start: () => void
  isCountdownFinished: () => boolean
  getAttemptNumber: () => number
  hasExistingGameData: () => boolean
  clearStatsAndSession: () => void
  updateGameDataWithFinishedGame: (line: Statline) => void
  handleKeynoteAndReturnStatus: (keyVal: string) => STATUS
  getFoundCount: () => number
  getNotesProducedCount: () => number
  getGameCompletionPercentage: () => number
  getPercentage: () => number
  getLostCount: () => number
  getGuessCount: () => number
  getCurrentStreak: () => number
  getBestStreak: () => number
  getStageNotes: () => Note[]
  getFinishedNotes: () => Note[]
  getStandbyNotes: () => Note[]
  getTextSprites: () => TextSprite[]
  getCorrectSprites: () => CorrectSprite[]
  runStandby: () => void
  playLastNote: () => void
  playStartNote: () => void
  allNotesPlayed: () => boolean
  getSolution: () => string[]
  getMissedNotes: () => string[]
  gameBoardClear: () => boolean
  handleNewFrameAndReturnStatus: (currentFrame: number) => STATUS
}

export const getGameLogic = () => {
  if (gameLogic) {
    return gameLogic
  }

  return initGameLogic()
}

const hasExistingGameData = () => {
  return gameData.allTime.averageFirstAttempt ? true : false
}

export const initGameLogic = () => {
  notesProduced = 0
  foundCount = 0
  lostCount = 0
  guessCount = 0
  bestStreak = 0
  currentStreak = 0
  missedNotes = []
  finishedNotes = []
  standbyNotes = []
  stageNotes = []
  textSprites = []

  getAudioHelper()
  initFrameLogic()
  gameData = getGameData(getDayIndex())

  if (gameData.today) {
    attemptNumber = gameData.today.totalAttempts
  } else {
    attemptNumber = 0
  }

  gameLogic = {
    start,
    isCountdownFinished,
    getAttemptNumber,
    hasExistingGameData,
    clearStatsAndSession,
    updateGameDataWithFinishedGame,
    handleKeynoteAndReturnStatus,
    getFoundCount,
    getNotesProducedCount,
    getGameCompletionPercentage,
    getPercentage,
    getLostCount,
    getGuessCount,
    getCurrentStreak,
    getBestStreak,
    getStageNotes,
    getFinishedNotes,
    getStandbyNotes,
    getTextSprites,
    getCorrectSprites,
    runStandby,
    playLastNote,
    playStartNote,
    allNotesPlayed,
    getSolution,
    getMissedNotes,
    gameBoardClear,
    handleNewFrameAndReturnStatus,
  }
  return gameLogic
}
