import { KeyValue } from '../lib/keyboard'
import { Keynote } from './Keynote'

type Props = {
  onKeynote: (value: string) => void
  onLastNotePlayback: () => void
  children?: JSX.Element
}

export const Keynoteboard = ({
  onKeynote,
  onLastNotePlayback,
  children,
}: Props) => {
  const onClick = (value: KeyValue) => {
    if (value === 'LAST_NOTE_PLAYBACK') {
      onLastNotePlayback()
    } else {
      onKeynote(value)
    }
  }

  return (
    <div className="flex flex-col h-full justify-between">
      <Keynote value="B" onClick={onClick} />
      <Keynote value="A#" onClick={onClick} />
      <Keynote value="A" onClick={onClick} />
      <Keynote value="G#" onClick={onClick} />
      <Keynote value="G" onClick={onClick} />
      <Keynote value="F#" onClick={onClick} />
      <Keynote value="F" onClick={onClick} />
      <Keynote value="E" onClick={onClick} />
      <Keynote value="D#" onClick={onClick} />
      <Keynote value="D" onClick={onClick} />
      <Keynote value="C#" onClick={onClick} />
      <Keynote value="C" onClick={onClick} />
      {children ? children : ''}
    </div>
  )
}
