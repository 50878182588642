import { v4 as uuidv4 } from 'uuid'

import { StoredGameData, InternalStoredGameData } from './gameData'
import { window } from './window'
const gameDataKey = 'gameData'

let _anonId: string = '' // private member
let _data: StoredGameData | null

export const clearStorageAndCache = (): void => {
  localStorage.removeItem(gameDataKey)
  _data = null
}

export const saveGameDataToLocalStorage = (gameData: StoredGameData) => {
  _data = gameData
  const isgs: InternalStoredGameData = {
    gameData: { ...gameData },
    anonId: getAnonId(),
  } // internal stored game state
  localStorage.setItem(gameDataKey, JSON.stringify(isgs))
}

export const loadGameDataFromLocalStorage = (): StoredGameData | null => {
  if (_data) {
    return _data
  }
  const data = window.localStorage.getItem(gameDataKey)
  if (!data) {
    return null
  }
  const parsed = JSON.parse(data)
  if (parsed.anonId) {
    _anonId = parsed.anonId
  }

  _data = {
    today: parsed.gameData.today,
    allTime: parsed.gameData.allTime,
  }

  return _data as StoredGameData
}

export const getAnonId = () => {
  if (!_anonId) {
    _anonId = uuidv4()
  }

  return _anonId
}
