import { getGameLogic } from '../../lib/gameLogic'
import { ProgressBar } from '../modals/ProgressBar'
import { FoundKeeper } from './FoundKeeper'
import { MissesKeeper } from './MissesKeeper'
import { StreakKeeper } from './StreakKeeper'

export const Scorebar = () => {
  return (
    <div className="flex flex-col">
      <ProgressBar
        progressPercentage={getGameLogic().getGameCompletionPercentage()}
      />

      <div className="flex max-h-40 justify-center text-xs sm:text-base">
        <div className="flex flex-col w-1/2 justify-center border border-solid">
          <StreakKeeper />
          <div className="flex flex-col justify-center"></div>
        </div>
        <div className="flex flex-col w-1/2 justify-center border border-solid">
          <FoundKeeper />
          <MissesKeeper />
        </div>
      </div>
    </div>
  )
}
