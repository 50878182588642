export const FPS = 50
export const MS_PER_SECOND = 1000
export const MS_PER_FRAME = (1 / FPS) * MS_PER_SECOND

let currentFrame: number
let timeToNextFrame: number
let timeSinceLastFrame: number

export const initFrameLogic = () => {
  currentFrame = 0
  timeToNextFrame = MS_PER_FRAME
  timeSinceLastFrame = 0
}

export const getCurrentFrame = () => {
  return currentFrame
}

export const incrementFrame = () => {
  return currentFrame++
}

let frameCount: number
export const getFramesToRender = (deltaTime: number): number => {
  frameCount = Math.floor((timeSinceLastFrame + deltaTime) / MS_PER_FRAME)

  if (timeSinceLastFrame + deltaTime < MS_PER_FRAME) {
    timeSinceLastFrame += deltaTime
  } else {
    timeSinceLastFrame = (timeSinceLastFrame + deltaTime) % MS_PER_FRAME
  }

  return frameCount
}

export const shouldRenderNewFrame = (deltaTime: number): boolean => {
  if (timeSinceLastFrame + deltaTime > timeToNextFrame) {
    return true
  }

  return false
}

let leftover: number
export const updateFrameLogic = (deltaTime: number): void => {
  if (shouldRenderNewFrame(deltaTime)) {
    leftover = (timeSinceLastFrame + deltaTime - timeToNextFrame) % MS_PER_FRAME
    timeSinceLastFrame = leftover
    timeToNextFrame = MS_PER_FRAME
    currentFrame += 1
  } else {
    timeSinceLastFrame += deltaTime
  }
}
